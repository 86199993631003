<script lang="ts" setup>
const globalStore = useGlobalStore()

definePageMeta({
  nested: false,
  showCart: true,
  shareable: true,
  keepalive: true,
  scrollToTop: false,
  context: 'products.list',
})

useSeoMeta(globalStore.getStoreSEOTags() as object)
</script>

<template>
  <RProductsPage />
</template>
